import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function BasketballIcon(props: SvgIconProps) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width={23.077} height={24} fill="none" {...props}>
      <path d="M11.539.462A11.539 11.539 0 1 0 23.077 12 11.551 11.551 0 0 0 11.539.462ZM3.994 5.806a9.674 9.674 0 0 1 2.178 5.307H1.815a9.713 9.713 0 0 1 2.178-5.308Zm8.431 5.307V2.278a9.718 9.718 0 0 1 5.401 2.26 11.434 11.434 0 0 0-2.705 6.575h-2.695Zm-1.774 0H7.955A11.434 11.434 0 0 0 5.25 4.538a9.718 9.718 0 0 1 5.4-2.26v8.834Zm-4.479 1.773a9.674 9.674 0 0 1-2.178 5.308 9.713 9.713 0 0 1-2.177-5.308h4.355Zm1.783 0h2.695v8.835a9.717 9.717 0 0 1-5.4-2.26 11.437 11.437 0 0 0 2.705-6.575Zm4.47 0h2.696a11.437 11.437 0 0 0 2.705 6.575 9.717 9.717 0 0 1-5.4 2.26v-8.834Zm4.48 0h4.355a9.713 9.713 0 0 1-2.178 5.308 9.674 9.674 0 0 1-2.178-5.308Zm0-1.774a9.674 9.674 0 0 1 2.178-5.308 9.713 9.713 0 0 1 2.177 5.308h-4.355Z" />
    </SvgIcon>
  );
}

export default BasketballIcon;
