import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function BaseballIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 24 24"
      width={24}
      height={24}
      {...props}
    >
      <g>
        <g>
          <g>
            <path d="M22.932 7.045c-0.684 -1.508 -1.657 -2.837 -2.89 -3.951l0 0 0 0C17.833 1.098 14.977 0 12 0 9.035 0 6.188 1.091 3.983 3.071l-0.001 0.001 -0.002 0.002C1.451 5.348 0 8.601 0 12c0 3.4 1.452 6.654 3.983 8.929l0 0 0.002 0.002C6.19 22.91 9.036 24 12 24c2.976 0 5.831 -1.098 8.038 -3.09l0.001 -0.001 0.002 -0.002c1.233 -1.114 2.206 -2.444 2.89 -3.951C23.641 15.394 24 13.727 24 12s-0.359 -3.394 -1.068 -4.955m-3.212 12.85q-0.006 -0.006 -0.012 -0.012c-0.188 -0.185 -0.491 -0.182 -0.676 0.006 -0.175 0.178 -0.182 0.458 -0.022 0.645C17.037 22.156 14.568 23.044 12 23.044c-2.555 0 -5.015 -0.88 -6.983 -2.489 0.158 -0.186 0.151 -0.465 -0.023 -0.643 -0.184 -0.189 -0.487 -0.193 -0.676 -0.008 -0.005 0.005 -0.01 0.009 -0.014 0.014C2.171 17.844 0.956 14.983 0.956 12S2.171 6.156 4.303 4.082q0.007 0.007 0.014 0.014a0.477 0.477 0 0 0 0.334 0.136 0.477 0.477 0 0 0 0.342 -0.144c0.174 -0.178 0.18 -0.457 0.023 -0.643C6.985 1.836 9.445 0.956 12 0.956c2.568 0 5.037 0.888 7.009 2.51 -0.159 0.186 -0.152 0.467 0.022 0.645a0.477 0.477 0 0 0 0.341 0.143 0.477 0.477 0 0 0 0.335 -0.137q0.006 -0.006 0.012 -0.012C21.837 6.177 23.044 9.029 23.044 12s-1.207 5.823 -3.323 7.895" />
            <path d="M16.139 14.295c0.261 -0.041 0.439 -0.286 0.397 -0.547a11.092 11.092 0 0 1 -0.103 -0.872 0.478 0.478 0 0 0 -0.514 -0.439c-0.263 0.021 -0.46 0.251 -0.439 0.514a12.096 12.096 0 0 0 0.112 0.947c0.037 0.235 0.241 0.403 0.472 0.403a0.478 0.478 0 0 0 0.076 -0.006" />
            <path d="M15.919 11.56q0.019 0.001 0.038 0.001c0.247 0 0.456 -0.19 0.476 -0.441a11.092 11.092 0 0 1 0.104 -0.871c0.041 -0.261 -0.136 -0.506 -0.397 -0.547 -0.26 -0.041 -0.506 0.136 -0.547 0.397a12.096 12.096 0 0 0 -0.112 0.947c-0.021 0.263 0.176 0.493 0.439 0.514" />
            <path d="M16.346 8.85a0.478 0.478 0 0 0 0.15 0.024c0.201 0 0.388 -0.128 0.454 -0.329a10.948 10.948 0 0 1 0.307 -0.822c0.102 -0.243 -0.012 -0.524 -0.255 -0.626 -0.243 -0.102 -0.524 0.012 -0.626 0.255a11.952 11.952 0 0 0 -0.334 0.893c-0.083 0.251 0.054 0.521 0.305 0.604" />
            <path d="M18.062 17.832c-0.14 -0.224 -0.434 -0.293 -0.659 -0.153 -0.224 0.14 -0.293 0.434 -0.153 0.659a12.096 12.096 0 0 0 0.536 0.789 0.478 0.478 0 0 0 0.385 0.194 0.476 0.476 0 0 0 0.284 -0.094c0.212 -0.157 0.257 -0.456 0.1 -0.669a11.092 11.092 0 0 1 -0.493 -0.726" />
            <path d="M17.256 16.274a10.996 10.996 0 0 1 -0.307 -0.822c-0.083 -0.251 -0.353 -0.387 -0.604 -0.305s-0.387 0.353 -0.305 0.603a11.952 11.952 0 0 0 0.334 0.894 0.478 0.478 0 0 0 0.441 0.293 0.478 0.478 0 0 0 0.185 -0.037c0.243 -0.102 0.358 -0.383 0.255 -0.626" />
            <path d="M18.457 4.772c-0.212 -0.157 -0.512 -0.112 -0.669 0.1a12.096 12.096 0 0 0 -0.536 0.789c-0.14 0.224 -0.071 0.519 0.153 0.659a0.478 0.478 0 0 0 0.659 -0.153 11.092 11.092 0 0 1 0.493 -0.726c0.157 -0.212 0.112 -0.512 -0.1 -0.669" />
            <path d="M5.974 6.15a0.478 0.478 0 0 0 0.659 0.152c0.224 -0.14 0.292 -0.435 0.152 -0.659a12.096 12.096 0 0 0 -0.54 -0.79c-0.157 -0.212 -0.457 -0.256 -0.669 -0.099s-0.256 0.457 -0.099 0.669a11.139 11.139 0 0 1 0.497 0.727" />
            <path d="M6.786 7.712a10.996 10.996 0 0 1 0.309 0.824c0.066 0.201 0.253 0.328 0.454 0.328a0.478 0.478 0 0 0 0.15 -0.024c0.251 -0.083 0.387 -0.353 0.304 -0.604a11.952 11.952 0 0 0 -0.336 -0.896c-0.103 -0.243 -0.383 -0.357 -0.626 -0.255 -0.243 0.103 -0.357 0.383 -0.255 0.626" />
            <path d="M6.632 17.7c-0.224 -0.14 -0.519 -0.072 -0.659 0.152a11.139 11.139 0 0 1 -0.497 0.727c-0.157 0.212 -0.113 0.511 0.099 0.669a0.476 0.476 0 0 0 0.285 0.094 0.478 0.478 0 0 0 0.384 -0.193 12.048 12.048 0 0 0 0.54 -0.79c0.14 -0.224 0.072 -0.519 -0.152 -0.659" />
            <path d="M8.455 10.095c-0.042 -0.261 -0.287 -0.438 -0.548 -0.397 -0.261 0.042 -0.438 0.287 -0.397 0.547a11.092 11.092 0 0 1 0.104 0.875c0.02 0.25 0.229 0.441 0.476 0.441q0.019 0 0.038 -0.001c0.263 -0.021 0.46 -0.251 0.439 -0.514a12.048 12.048 0 0 0 -0.113 -0.951" />
            <path d="M7.699 15.162c-0.251 -0.083 -0.521 0.053 -0.604 0.304a10.996 10.996 0 0 1 -0.31 0.824c-0.103 0.243 0.011 0.524 0.255 0.626 0.061 0.026 0.124 0.038 0.186 0.038 0.186 0 0.364 -0.11 0.441 -0.292a12 12 0 0 0 0.337 -0.896c0.083 -0.251 -0.053 -0.521 -0.304 -0.604" />
            <path d="M8.129 12.443c-0.263 -0.021 -0.493 0.176 -0.514 0.439a11.092 11.092 0 0 1 -0.104 0.875c-0.042 0.261 0.136 0.506 0.397 0.548q0.038 0.006 0.076 0.006c0.231 0 0.434 -0.168 0.471 -0.403a12.048 12.048 0 0 0 0.113 -0.95c0.021 -0.263 -0.176 -0.493 -0.439 -0.514" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default BaseballIcon;
