import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function TennisIcon(props: SvgIconProps) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width={23.077} height={24} fill="none" {...props}>
      <path
        fill="#ECECEC"
        d="M7.12 1.338a11.654 11.654 0 0 1 4.419-.876c1.511 0 3.011.3 4.42.877a11.511 11.511 0 0 1 3.738 2.503A11.511 11.511 0 0 1 22.2 7.58a11.66 11.66 0 0 1 .877 4.42c0 3.058-1.212 6-3.381 8.157a11.509 11.509 0 0 1-8.157 3.382 11.653 11.653 0 0 1-4.419-.877 11.51 11.51 0 0 1-3.738-2.504A11.508 11.508 0 0 1 0 12c0-3.058 1.212-6 3.381-8.158a11.511 11.511 0 0 1 3.738-2.503ZM5.643 19.096a9.204 9.204 0 0 0 11.791 0A11.5 11.5 0 0 1 15 12c0-2.677.912-5.135 2.434-7.097a9.181 9.181 0 0 0-5.895-2.134 9.21 9.21 0 0 0-5.897 2.135A11.526 11.526 0 0 1 8.077 12a11.5 11.5 0 0 1-2.435 7.097Zm-1.604-1.708A9.26 9.26 0 0 0 5.769 12a9.194 9.194 0 0 0-1.731-5.377A9.22 9.22 0 0 0 2.308 12c0 1.938.612 3.819 1.731 5.388Zm15-10.766A9.198 9.198 0 0 0 17.308 12c0 2.008.646 3.866 1.731 5.388A9.254 9.254 0 0 0 20.769 12a9.223 9.223 0 0 0-1.731-5.377Z"
      />
    </SvgIcon>
  );
}

export default TennisIcon;
