import { Box, IconButton, Modal, styled } from "@mui/material";
import React, { ComponentProps } from "react";
import CloseRoundedIcon from "../Icons/CloseRoundedIcon";

const StyledBox = styled(Box)(
  ({ theme }) => `
    justify-content: center;
    flex-direction: column;
    background-color: ${theme.palette.primary.main};
    border: 1px solid ${theme.palette.border.primary};
    padding: ${theme.spacing(2)};
    margin: auto;
    max-width: 80%;
    position: relative;
    overflow: auto;
    max-height: 100vh;
    display: block;
    border-radius: ${theme.borderRadius(1)};
    
    ${theme.breakpoints.down("md")} {
      margin-top: 12px;
    }

    hr {
      border-color: ${theme.palette.border.secondary};
      border-style: solid;
      border-width: 0;
      border-bottom-width: thin;
    }
  `,
);

const StyledIconButton = styled(IconButton)(
  ({ theme }) => `
    position: absolute;
    top: ${theme.spacing(1)};
    right: ${theme.spacing(1)};
  `,
);

type InfoModalModalProps = {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  hideCloseBtn?: boolean;
} & ComponentProps<typeof Box>;

function InfoModalModal({ open, onClose, children, hideCloseBtn, ...restProps }: InfoModalModalProps) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        // Align this Center Vertically and Horizontally.
        display: "flex",
      }}
    >
      <StyledBox alignSelf="center" height="max-content" width="max-content" margin="auto" {...restProps}>
        {!hideCloseBtn && (
          <StyledIconButton onClick={onClose} disableRipple disableTouchRipple>
            <CloseRoundedIcon sx={{ fontSize: 16 }} />
          </StyledIconButton>
        )}
        {children}
      </StyledBox>
    </Modal>
  );
}

export default InfoModalModal;
