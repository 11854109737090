import React from "react";
import { SvgIconProps } from "@mui/material";
import FootballIcon from "./FootballIcon";
import BasketballIcon from "./BasketballIcon";
import BaseballIcon from "./BaseballIcon";
import IceHockeyIcon from "./IceHockeyIcon";
import SoccerIcon from "./SoccerIcon";
import { Sports } from "../Common/Enums/SportsEnum";
import TennisIcon from "./TennisIcon";

type SportIconProps = {
  sportType: Sports;
} & SvgIconProps;

function SportIcon({ sportType, ...restProps }: SportIconProps) {
  let icon: JSX.Element | null;

  switch (sportType) {
    case Sports.FOOTBALL:
      icon = <FootballIcon {...restProps} />;
      break;
    case Sports.ICE_HOCKEY:
      icon = <IceHockeyIcon {...restProps} />;
      break;
    case Sports.BASKETBALL:
      icon = <BasketballIcon {...restProps} />;
      break;
    case Sports.BASEBALL:
      icon = <BaseballIcon {...restProps} />;
      break;
    case Sports.SOCCER:
      icon = <SoccerIcon {...restProps} />;
      break;
    case Sports.TENNIS:
      icon = <TennisIcon {...restProps} />;
      break;
    default:
      icon = null; // or some default icon
  }

  return <>{icon}</>;
}

export default SportIcon;
