import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function IceHockeyIcon(props: SvgIconProps) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width={23.143} height={24} fill="none" {...props}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={2}
        strokeWidth={1.714}
        d="m18.714 1.286-3.435 14.853c-.119.504-.601.861-1.166.861H2.642c-.987 0-1.785.726-1.785 1.626v.84c0 1.886 1.756 3.375 3.827 3.24l10.155-.661a3.76 3.76 0 0 0 2.091-.794 3.201 3.201 0 0 0 1.146-1.78l4.21-18.185h-3.572Zm-12.5 6.502c2.63 0 4.762-.728 4.762-1.625 0-.898-2.133-1.626-4.762-1.626s-4.762.729-4.762 1.626c0 .897 2.133 1.625 4.762 1.625Z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={2}
        strokeWidth={1.714}
        d="M10.977 6.163v3.25c0 .9-2.131 1.626-4.761 1.626-2.631 0-4.762-.726-4.762-1.625V6.161"
      />
    </SvgIcon>
  );
}

export default IceHockeyIcon;
