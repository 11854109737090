import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function SoccerIcon(props: SvgIconProps) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width={23.077} height={24} fill="none" {...props}>
      <path d="m17.227 17.908-.923-1.569 1.685-5.042 1.626-.543 1.154.866v.162c0 .08.035.15.035.219 0 2.274-.762 4.28-2.274 6.011l-1.303-.103Zm-8.286-2.446-1.58-4.65 4.178-2.931 4.177 2.931-1.58 4.65H8.941Zm2.597 5.804a8.538 8.538 0 0 1-2.885-.486l-.797-1.742.762-1.269h5.897l.704 1.269-.797 1.742a8.554 8.554 0 0 1-2.885.486Zm-6.992-3.255c-.611-.714-1.142-1.673-1.592-2.838-.45-1.177-.681-2.238-.681-3.173 0-.069.035-.138.035-.22v-.162l1.154-.865 1.627.543 1.685 5.043-.923 1.569-1.304.104Zm5.838-13.754v1.615L5.769 9.069l-1.546-.484-.485-1.57c.508-.785 1.257-1.569 2.25-2.331.991-.762 1.938-1.258 2.838-1.512l1.558 1.085Zm3.866-1.085c.9.255 1.846.75 2.838 1.512s1.743 1.547 2.251 2.331l-.485 1.569-1.546.496-4.615-3.196V4.257l1.558-1.085Zm-10.87.669C1.154 6.103 0 8.827 0 12c0 3.174 1.154 5.896 3.381 8.157 2.226 2.262 4.985 3.381 8.157 3.381 3.174 0 5.896-1.154 8.157-3.381 2.262-2.226 3.382-4.983 3.382-8.157 0-3.174-1.154-5.897-3.381-8.158-2.227-2.262-4.985-3.38-8.157-3.38-3.174 0-5.897 1.153-8.159 3.38Z" />
    </SvgIcon>
  );
}

export default SoccerIcon;
