/*
  While adding a new league. You need to add its GameType in IGameTypeFragment.ts and TeamType in ITeamTypeFragment.ts also.
  Also add the ...on GameType in buildToolsSubscriptionFragment in ToolsSubscriptionFragment.ts, Odds/common.ts and SportsBook/common.ts.
  and in formatLeagueName
  For Icon you need to create it's icon and add it in getSportsOfLeague.ts and SportIcon.tsx if it's a new sports
*/

export enum League {
  NBA = "NBA",
  NHL = "NHL",
  NFL = "NFL",
  MLB = "MLB",
  NCAAF = "NCAAF",
  NCAAB = "NCAAB",
  EURO_LEAGUE_BB = "EURO_LEAGUE_BB",
  EUFA_CHAMPIONS = "EUFA_CHAMPIONS",
  LA_LLGA = "LA_LLGA",
  ENGLISH_PREMIER = "ENGLISH_PREMIER",
  FRENCH_LIGUE_1 = "FRENCH_LIGUE_1",
  GERMAN_BUNDESLIGA = "GERMAN_BUNDESLIGA",
  ITALIAN_SERIA_A = "ITALIAN_SERIA_A",
  NCAA_BASEBALL = "NCAA_BASEBALL",
  TENNIS = "TENNIS",
  WNBA = "WNBA",
  UEFA_EURO = "UEFA_EURO",
  COPA_AMERICA = "COPA_AMERICA",
  CFL = "CFL",
  MLS = "MLS",
  BRAZIL_SERIE_A = "BRAZIL_SERIE_A",
  NBA_SUMMER = "NBA_SUMMER",
  OLYMPICS_BASKETBALL = "OLYMPICS_BASKETBALL",
  OLYMPICS_BASKETBALL_WOMEN = "OLYMPICS_BASKETBALL_WOMEN",
  OLYMPICS_SOCCER = "OLYMPICS_SOCCER",
  OLYMPICS_SOCCER_WOMEN = "OLYMPICS_SOCCER_WOMEN",
}
