import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function FootballIcon(props: SvgIconProps) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width={24} height={22.909} fill="none" {...props}>
      <path d="M22.79 3.292A3.625 3.625 0 0 0 19.823.324C16.256-.28 9.535-.557 4.593 4.388-.351 9.334-.076 16.052.528 19.617a3.626 3.626 0 0 0 2.974 2.968c1.249.213 2.513.322 3.781.324 3.535 0 7.935-.881 11.443-4.388 4.944-4.943 4.669-11.664 4.064-15.229Zm-6.785-1.543c1.181.004 2.361.108 3.524.309a1.865 1.865 0 0 1 1.527 1.527c.226 1.318.326 2.653.297 3.989l-5.815-5.813a8.138 8.138 0 0 1 .467-.011Zm-12.214 19.1a1.865 1.865 0 0 1-1.527-1.527 20.771 20.771 0 0 1-.297-3.988l5.813 5.813a20.685 20.685 0 0 1-3.989-.298Zm13.691-3.573c-1.279 1.277-3.665 3.055-7.42 3.664l-7.888-7.888c.611-3.756 2.387-6.142 3.664-7.42 1.277-1.279 3.663-3.055 7.418-3.665l7.891 7.889c-.611 3.756-2.387 6.142-3.665 7.42Zm-1.677-9.967a.881.881 0 0 1 0 1.246l-1.58 1.578.698.698a.88.88 0 1 1-1.245 1.244l-.698-.697-1.396 1.399.698.698a.88.88 0 1 1-1.246 1.245l-.698-.698-1.58 1.578a.879.879 0 0 1-1.455-.275.88.88 0 0 1 .209-.971l1.58-1.577-.698-.698a.881.881 0 0 1 1.246-1.246l.698.698 1.396-1.399-.698-.698a.881.881 0 0 1 1.245-1.246l.7.698 1.578-1.577a.881.881 0 0 1 1.245 0Z" />
    </SvgIcon>
  );
}

export default FootballIcon;
